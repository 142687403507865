import { Text} from '@fluentui/react';
import React, { useState } from 'react';
import Layout from "components/Layout";
import services from "config/services.yml";
import ServiceCard from './ServiceCard';

export const Services = () => {

    const [openModalProp, setOpenModalProp] = useState<string | null>(null);

    const showModal = (prop: string) => setOpenModalProp(prop);
    const hideModal = () => setOpenModalProp(null);


    return (
        <>
            <Layout onGrid={false} allowAnnouncement={false} >
            <div style={{ textAlign: "center", fontFamily: "markOT, system-ui, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif !important" }}>
                <h3 style={{ color: "#083a59", fontSize: "2.5rem", fontWeight: "600"}}>Service Catalogue</h3>
                <div style={{ maxWidth: "815px", margin: "0 auto", textAlign: "center"}}>
                <Text
                    variant="large"
                    style={{ fontWeight: "300"}}
                >
                    The service catalogue page features a comprehensive suite of offerings from the Earth observation Data Center, specializing in advanced solutions for processing, analyzing, and visualizing earth observation data to support research and decision-making in environmental monitoring and management.
                </Text>
                </div>
                <div style={{ paddingTop: "3em", paddingLeft: "10%", paddingRight: "10%", paddingBottom: "10%" }}>
                    {Object.keys(services).map((prop) => (
                    <ServiceCard
                        key={prop}
                        service={services[prop]}
                        isOpen={openModalProp === prop}
                        onShowModal={() => showModal(prop)}
                        onHideModal={hideModal}
                    />
                ))}
                </div>
            </div>
            </Layout>
        </>
  );
};
