import { useEffect, useState, useRef } from "react";

import L from "leaflet";
import 'leaflet/dist/leaflet.css';

import { useExploreSelector } from "../../state/hooks";
import {
  useItemBoundsLayer,
  useMapDrawTools,
  useItemLayer,
  useEventHandlers
} from "./hooks";

import { setBounds } from "../../state/mapSlice";
import { useExploreDispatch } from "pages/Explore/state/hooks";


const mapContainerId: string = "viewer-map";
const defaultGlobalBounds: L.LatLngBounds = new L.LatLngBounds(
  new L.LatLng(-90, -180), // Southwest corner [latitude, longitude]
  new L.LatLng(90, 180)     // Northeast corner [latitude, longitude]
);

const ExploreMap = () => {
  const mapRef = useRef<L.Map | null>(null);
  const { center, zoom, showSidebar } = useExploreSelector(s => s.map);
  const [mapReady, setMapReady] = useState<boolean>(false);
  const baseLayerRef = useRef<L.TileLayer | null>(null);
  const mapHandlers = useEventHandlers();
  const dispatch = useExploreDispatch();


  // Initialize the map
  useEffect(() => {
    const onReady = () => {
      setMapReady(true);
    };

    if (!mapRef.current) {

      const map = L.map(mapContainerId, {
        maxBounds: defaultGlobalBounds,
        minZoom: 3,
        maxZoom: 18,
        zoom: Math.min(Math.max(zoom, 3), 18)
      }).setView(center, zoom);
      const mapBdns = map.getBounds()

      const bounds = [
        mapBdns.getWest(),
        mapBdns.getSouth(),
        mapBdns.getEast(),
        mapBdns.getNorth()
      ] as [number, number, number, number];

      dispatch(setBounds(bounds));
      // Define base layers
      const osmLayer = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        attribution: '&copy <a href="https://www.openstreetmap.org/copyright" target="_blank">OpenStreetMap</a>'
      });

      const lightGrayLayer = L.tileLayer('https://server.arcgisonline.com/ArcGIS/rest/services/Canvas/World_Light_Gray_Base/MapServer/tile/{z}/{y}/{x}', {
        attribution: 'Esri, HERE, Garmin, <a href="https://www.openstreetmap.org/copyright" target="_blank">OpenStreetMap</a>'
      });

      const darkGrayLayer = L.tileLayer('https://server.arcgisonline.com/ArcGIS/rest/services/Canvas/World_Dark_Gray_Base/MapServer/tile/{z}/{y}/{x}', {
        attribution: 'Esri, HERE, Garmin, <a href="https://www.openstreetmap.org/copyright" target="_blank">OpenStreetMap</a>'
      });

      // Add default layer to map
      osmLayer.addTo(map);

      // Add layer control
      L.control.layers({
        '<img style="vertical-align:middle" width="50px" src="images/basemap_icon_osm.png">': osmLayer,
        '<img style="vertical-align:middle" width="50px" src="images/basemap_icon_light_gray.png">': lightGrayLayer,
        '<img style="vertical-align:middle" width="50px" src="images/basemap_icon_dark_gray.png">': darkGrayLayer,
      }).addTo(map);

      // Handle base layer change event
      map.on('baselayerchange', (event: L.LayersControlEvent) => {
        if (baseLayerRef.current) {
          baseLayerRef.current.bringToBack();
        }
        baseLayerRef.current = event.layer as L.TileLayer;
        baseLayerRef.current.bringToBack();
      });

      map.whenReady(onReady)
      map.on("zoomend", mapHandlers.onMapMove)
      map.on("moveend", mapHandlers.onMapMove)
      mapRef.current = map;
    }
  }, [
    center,
    zoom,
    mapHandlers.onMapMove
  ]);

  // useEffect(() => {
  //   const elNames = ["#atlas-map-shortcuts", "#atlas-map-style", "#atlas-map-state"];
  //   elNames.forEach(elName => {
  //     const els = document.querySelectorAll(elName);
  //     if (els.length === 2) {
  //       els[1].remove();
  //     }
  //   });
  // }, [mapReady]);

  useItemBoundsLayer(mapRef, mapReady);
  useMapDrawTools(mapRef, mapReady);
  useItemLayer(mapRef, mapReady);

  // Class used to sync state via responsive media queries in css
  const visibilityClass = !showSidebar ? "explorer-sidebar-hidden" : "";

  return (
    <div className={`explorer-map ${visibilityClass}`} style={mapContainerStyle}>

      <div id={mapContainerId} style={mapElementStyle} />
    </div>
  );
};

export default ExploreMap;

const mapContainerStyle: React.CSSProperties = {
  width: "100%",
  height: "100%",
  position: "relative",
};

const mapElementStyle = { width: "100%", height: "100%" };
