import { mergeStyles } from "@fluentui/react";


export const dropDownDiv = mergeStyles({
  marginRight: '20rem',
  position: 'absolute',
  right: 0,
  zIndex: '1001',
  overflow: 'hidden',
  transition: 'height 0.3s ease, opacity 0.3s ease',
  top: '100%',
  '@media (max-width: 1350px)': { marginRight: '0 !important'}
});


export const dropDownButton = mergeStyles({
  height: '2.5rem !important',
  width: '15vw',
  background: 'rgb(248, 247, 247)',
  color: '#737b8d',
  border: 0,
  fontSize: '16px !important',
  filter: 'drop-shadow(0px 2px 3px rgb(22, 158, 176, 0.35))',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
});

export const welcomeCard = mergeStyles({
  height: '2.5rem !important',
  width: '15vw',
  background: 'rgb(248, 247, 247)',
  color: '#737b8d',
  paddingTop: '1em',
  textAlign: 'center',
  border: 0,
  fontSize: '16px !important',
  overflow: 'hidden',
});


export const profileDropDownButton = mergeStyles({
  "height": '7rem',
  "marginRight": '20rem',
  "border": 'none',
  "color": '#737b8d',
  "transform": 'scaleX(-1)',
  "zIndex": 1,
  selectors: {
    ':hover': { backgroundColor: '#fff', color: '#083a59' },
    ':active': { backgroundColor: '#fff' },
    }
  }
);
