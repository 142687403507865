import { CSSProperties } from "react";
import { Card } from 'react-bootstrap';
import { DefaultButton, Icon, Text } from '@fluentui/react';
import Layout from "components/Layout";
import dashboards from "config/dashboards.yml";
import { useWindowSize } from "react-use";

import { 
    cardStyles, 
    cardHoverStyles,
    imageContainerStyles,
    imageStyles,
    buttonStyles
} from './styles';

// TODO: track heights rather than hard code them
const heights = {
    header: 152,
    footer: 27,
    buffer: 1,
  };

export const Gallery = () => {
    const { height } = useWindowSize();
    const bodyHeight = height - heights.header - heights.footer - heights.buffer;
    const mainStyle: CSSProperties = { minHeight: bodyHeight };

    const contents = [];
    for(let prop in dashboards) {
        contents.push(
            // Card given a slight zoom on hover animation
            <Card key={prop} style={cardStyles} onMouseEnter={(e) => {
                Object.assign(e.currentTarget.style, cardHoverStyles);
            }} 
            onMouseLeave={(e) => {
                Object.assign(e.currentTarget.style, cardStyles);
            }}
            >
        <div 
            style={imageContainerStyles} 
            onMouseEnter={(e) => {
                const button = e.currentTarget.querySelector('.hover-button') as HTMLButtonElement;
                const image = e.currentTarget.querySelector('.dashboard-img') as HTMLImageElement;
                if (button && image) {
                    button.style.display = 'block';
                    image.style.opacity = "0.5";
                }
            }}
            onMouseLeave={(e) => {
                const button = e.currentTarget.querySelector('.hover-button') as HTMLButtonElement;
                const image = e.currentTarget.querySelector('.dashboard-img') as HTMLImageElement;
                if (button && image) {
                    button.style.display = 'none';
                    image.style.opacity = "1";
                }
            }}
        >
            <Card.Img 
                className="dashboard-img"
                variant="top" 
                src="./images/rossihni_dashboard.png" 
                style={imageStyles} 
            />
            <DefaultButton 
                className="hover-button" 
                style={buttonStyles}
                onClick={() => window.open(dashboards[prop].src, '_blank')}
            >
                View App
                <Icon iconName='OpenInNewTab' style={{ "paddingLeft": "0.5rem"}}></Icon>
            </DefaultButton>
        </div>
                <Card.Body style={{ height: "250px", overflow: 'hidden', position: 'relative'
}}>
                    <Card.Title style={{fontWeight: "400", textAlign: "left", paddingTop: "1rem", paddingLeft: "1rem", fontSize: 20, color: "#083a59"}}>{dashboards[prop].title}</Card.Title>
                    <Card.Text style={{textAlign: "left", paddingLeft: "1rem", paddingRight: "1rem", fontSize: 14 }}>
                    {
                        dashboards[prop].description.length > 150
                        ? dashboards[prop].description.substring(0, 150) + "..." 
                        :
                        dashboards[prop].description
                    }
                    </Card.Text>
                </Card.Body>
            </Card>
        )
    }

    return (
        <>
            <Layout onGrid={false} allowAnnouncement={false} mainStyle={mainStyle}>
            <div style={{ textAlign: "center", fontFamily: "markOT, system-ui, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif !important" }}>
                <h3 style={{ color: "#083a59", fontSize: "2.5rem", fontWeight: "600"}}>Dashboard Gallery</h3>
                <div style={{ maxWidth: "815px", margin: "0 auto", textAlign: "center"}}>
                <Text
                    variant="large"
                    style={{ fontWeight: "300"}}
                >
                    Discover our ready-to-use applications that transform Earth observation data into actionable insights. Explore tools designed for intuitive analysis of environmental and geospatial information, making complex datasets clear and accessible. Enhance your decision-making with our powerful, data-driven dashboards.
                </Text>
                </div>
                <div style={{ paddingTop: "0", paddingLeft: "10%", paddingRight: "10%", paddingBottom: "0%" }}>
                    {contents}
                </div>
            </div>
            </Layout>
        </>
  );
};
