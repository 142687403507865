import { BasePeoplePicker, Text } from "@fluentui/react";

const HomeBanner = () => {
    return (
        <div style={{  
            position: 'relative', 
            width: "100%", 
            height: "30vh",
            minHeight: "30vh",
            objectFit: "cover", 
            overflow: "hidden",
            }}>
        <img
            src="./images/eodc_background_blue_min.jpg"
            alt="Banner"
            style={{  
                position: 'relative', 
                width: "100%", 
            }}
        />
        <Text
            // variant="xLarge"
            style={{
                position: 'absolute',
                bottom: '5%', // Adjust this value to position the text from the bottom
                // transform: 'translateX(-100%)', // Center the text
                // horizontally
                left: "10%",
                color: 'white',
                textAlign: 'center',
                fontWeight: '70',
                fontSize: "3rem",
                // maxWidth: "100%",
                width: "80%",
                fontFamily: "markOT, system-ui, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif !important",
                zIndex: 1, // Ensures the text is above the image
                margin: '0 auto', // Ensures centering if text wraps
            }}
        >
            Designed to make your life easier
        </Text>
        </div>
    );
};

export default HomeBanner;
