import React from 'react';
import { IconButton } from '@fluentui/react';
import hljs from 'highlight.js';
import 'highlight.js/styles/default.css';

interface CodeBlockProps {
    code: string;
    language: string;
}

const CodeBlock: React.FC<CodeBlockProps> = ({ code, language }) => {
    const copyToClipboard = () => {
        navigator.clipboard.writeText(code);
    };

    return (
        <div style={{ position: 'relative', border: '1px solid #ccc', borderRadius: '4px', padding: '1rem', marginBottom: '1rem' }}>
            <IconButton 
                iconProps={{ iconName: 'Copy' }} 
                title="Copy"
                style={{ position: 'absolute', top: '10px', right: '10px' }} 
                onClick={copyToClipboard} 
            />
            <pre>
                <code dangerouslySetInnerHTML={{ __html: hljs.highlight(code, { language }).value }} />
            </pre>
        </div>
    );
};

export default CodeBlock;