import { CSSProperties } from "react";
import { useWindowSize } from "react-use";
import { Text, Icon, Separator } from "@fluentui/react";
import Layout from "components/Layout";
import HomeBanner from "./components/HomeBanner";
import { separator } from "./styles"


// TODO: track heights rather than hard code them
const heights = {
  header: 112,
  footer: 27,
  buffer: 1,
};

export const Home = () => {
  const { height } = useWindowSize();
  const bodyHeight = height - heights.header - heights.footer - heights.buffer;
  const mainStyle: CSSProperties = { minHeight: bodyHeight };

  
  const handleNavigation = (url: string) => {
    window.location.href = url;
  };


  return (
    <>
      <Layout onGrid={false} allowAnnouncement={false} mainStyle={mainStyle} >
        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", textAlign: "center" }}>
          <HomeBanner/>
          <Text
            block
            variant="large"
            className="grid-content"
            style={{ display: "flex", alignItems: "center", maxWidth: "815px", color: "#083a59", fontWeight: "600", minHeight: "20vh", fontSize: "1.6em"}}
          >
            By translating complex datasets into comprehensible information, we are
            instrumental in crafting valuable and cutting-edge solutions. Cultivating
            sustainable relationships is in our DNA, providing the foundation for the
            creation of impactful and user-friendly services.
          </Text>
          <div style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "top",
              width: "100%",
            }}>
              <div className="home-grid-content">
                <a onClick={() => handleNavigation('/data_catalogue')}>
                  <Icon iconName="Globe" />
                  <h3>Data Catalogue</h3>
                </a>
                <Text block>
                Explore our Data Catalogue for high-resolution Earth observation data, including detailed satellite imagery and climate trends. Empower your research and innovation with comprehensive insights into environmental and urban changes.
                </Text>
              </div>

              <Separator
                vertical
                className={separator}
              />
              <div className="home-grid-content">
                <a onClick={() => handleNavigation('/gallery')}>
                  <Icon iconName="WebAppBuilderFragment" />
                  <h3>Gallery</h3>
                </a>
                <Text block>
                  Explore our gallery of applications, crafted to simplify and enhance your workflows with intuitive, user-friendly interfaces. Empower your productivity and innovation with tools designed to meet your diverse needs.
                </Text>
              </div>

              <Separator
                vertical
                className={separator}
              />

              <div className="home-grid-content">
                <a onClick={() => handleNavigation('/docs')}>
                  <Icon iconName="DocumentSet" />
                  <h3>Documentation</h3>
                </a>
                <Text block>
                Discover our EODC Knowledgebase, your primary destination for in-depth insights and information. This platform offers straightforward explanations and practical examples to assist you in effectively accessing our data and resources.
                </Text>
              </div>

            </div>
        </div>
      </Layout>
    </>
  );
};
