import { useCallback } from "react";
import { useExploreDispatch } from "pages/Explore/state/hooks";
import { setCenter, setZoom, setBounds } from "pages/Explore/state/mapSlice";

const useEventHandlers = (
  ) => {
    const dispatch = useExploreDispatch();
    const onMapMove = useCallback(
        (e) => {
            const map = e.target;
            if (!map) {
                return
            } else {
                const center = map.getCenter();
                const zoom = map.getZoom();
                const mapBdns = map.getBounds()
                const bounds = [
                    mapBdns.getWest(), 
                    mapBdns.getSouth(),
                    mapBdns.getEast(),
                    mapBdns.getNorth()
                ] as [number, number, number, number];
                
                dispatch(setCenter([center.lat, center.lng]));
                dispatch(setZoom(zoom));
                dispatch(setBounds(bounds));        
            }
        },
        [dispatch]
      );
    return { onMapMove };
};

export default useEventHandlers;