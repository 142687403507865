import { DefaultButton, Stack, FocusZone, Text } from '@fluentui/react';
import { useAuth } from 'react-oidc-context';
import { LoginButton } from './LoginButton';
import { LogoutButton } from './LogoutButton';

import { useState, useRef, useEffect} from 'react';

import { dropDownDiv, welcomeCard, profileDropDownButton } from "./styles";

export const UserMenu = () => {

  const [iconName, setIconName] = useState('Contact');
  const [isOptionsVisible, setIsOptionsVisible] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const auth = useAuth();

  const ondropDownClick = () => {

    if (isAnimating) return;

    setIsOptionsVisible(!isOptionsVisible);
    setIsAnimating(true);

    if (dropdownRef.current) {
      setTimeout(() => {
        if (isOptionsVisible) {
          dropdownRef.current!.style.height = '0px';
          dropdownRef.current!.style.opacity = '0';
        } else {
          dropdownRef.current!.style.height = dropdownRef.current!.scrollHeight + 'px';
          dropdownRef.current!.style.opacity = '1';
        }
      }, 10);
    }
  };

  const onTransitionEnd = () => {
    setIsAnimating(false);
    if (!isOptionsVisible && dropdownRef.current) {
      dropdownRef.current.style.height = '0px';
    }
  };


  useEffect(() => {
    if (dropdownRef.current && isOptionsVisible) {
      dropdownRef.current.style.height = dropdownRef.current.scrollHeight + 'px';
      dropdownRef.current.style.opacity = '1';
    }
  }, [isOptionsVisible]);

return (
  <>
    <div style={{ position: 'relative', display: 'inline-block' }}>
      <DefaultButton 
        iconProps={{ iconName }}  
        className={profileDropDownButton} 
        onClick={ondropDownClick} 
      />
      <div
        ref={dropdownRef}
        className={dropDownDiv}
        style={{
          height: isOptionsVisible ? 'auto' : '0px',
          opacity: isOptionsVisible ? 1 : 0,
        }}
        onTransitionEnd={onTransitionEnd}
      >
        <FocusZone>
          <Stack tokens={{ childrenGap: 0 }}>
          { auth.isAuthenticated ? (
              <>
              <Text className={welcomeCard} variant="large">Welcome, {auth.user?.profile.given_name}!</Text>
              <LogoutButton />
              </>
            ) : (
              <LoginButton />
          )}
          </Stack>
        </FocusZone>
      </div>
    </div>
  </>
);
};

