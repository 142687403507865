import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from 'react-oidc-context';


export const CallbackPage = () => {

  const auth = useAuth();
  const navigate = useNavigate();

  useEffect(() => {

    if ( auth.isAuthenticated ) {
      navigate("/");
    }

  }, [auth, navigate]);


  return<div>Processing OAuth callback...</div>;
};