import { AuthProvider } from 'react-oidc-context';
import { useConfig } from "ConfigProvider";


const KeycloakProvider = ({ children }) => {
  const appConfig = useConfig();

  return (
    <AuthProvider  {...appConfig.config.oidcConfig}>
      {children}
    </AuthProvider>
  );
};

export default KeycloakProvider;
