import { DefaultButton } from '@fluentui/react';

import { dropDownButton } from "./styles";
import { useAuth } from 'react-oidc-context';

export const LoginButton = () => {
  const auth = useAuth();

  const handleLogin = () => {
    auth.signinRedirect();
  };

  const disabled = ( !auth.settings.authority ) ? true : false

  return (
    <DefaultButton
        text='Login'
        className={dropDownButton}
        disabled={disabled}
        onClick={handleLogin}
    />
  );
};