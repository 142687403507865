import React, { Suspense } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { initializeIcons } from "@uifabric/icons";

import { initializeFeatures } from "utils/featureFlags";
import { registerCustomIcons } from "utils/icons";
import { usePrefetchContent } from "utils/requests";
import Explorer from "pages/Explore";
import Collection from "pages/Collection";
import Catalog from "./pages/Catalog2";
import CatalogGroup from "pages/CatalogGroup";
import Home from "pages/Home";
import { Gallery } from 'pages/Gallery';
import { Services } from 'pages/Services';
import NotFound from "pages/NotFound";
import Terms from "pages/Terms";
import Layout from "./components/Layout";

import { CallbackPage } from "./pages/OidcCallback";

const Docs = React.lazy(() => import("pages/Docs/Docs.index"));

function App() {
  initializeIcons(undefined, { disableWarnings: true });
  registerCustomIcons();
  initializeFeatures();
  usePrefetchContent();

  const pageFallback = (
    <Layout onGrid={false} isShort={true} allowAnnouncement={false}></Layout>
  );

  return (
      <div>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/explore" element={<Explorer />} />
          <Route path="/data_catalogue" element={<Catalog />} />
          <Route path="/service_catalogue" element={<Services />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/dataset/group/:groupId" element={<CatalogGroup />} />
          <Route path="/dataset/:id" element={<Collection />} />
          <Route path="/docs/*" element={ <Suspense fallback={pageFallback}> <Docs /> </Suspense> } />\
          <Route path="/oauth2/callback" element={ <CallbackPage /> } />\
          <Route path="/404" element={<NotFound />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
  );
}

export default App;
