import { BrowserRouter } from "react-router-dom";
import ReactDOM from "react-dom";
import { ThemeProvider } from "@fluentui/react";
import { QueryClient, QueryClientProvider } from "react-query";

import "./styles/index.css";
import App from "./App";

import collections from "config/datasets.yml";
import groups from "config/datasetGroups.yml";
import featuredCollections from "config/datasetFeatured.yml";

import { ConfigProvider } from "./ConfigProvider";
import { DataConfigProvider } from "components/state/DataConfigProvider";
import KeycloakProvider from "components/auth-keycloak/keycloakprovider";

const queryClient = new QueryClient();


ReactDOM.render(
  <ThemeProvider>
    <QueryClientProvider client={queryClient}>
        <DataConfigProvider
          collectionConfig={collections}
          groupConfig={groups}
          featuredIds={featuredCollections}
        >
          <ConfigProvider>
            <BrowserRouter>
              <KeycloakProvider>
                <App />
              </KeycloakProvider>
            </BrowserRouter>
          </ConfigProvider>
        </DataConfigProvider>
    </QueryClientProvider>
  </ThemeProvider>,
  document.getElementById("root")
);

