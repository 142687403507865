import React from 'react';
import { Card } from 'react-bootstrap';
import { Modal, DefaultButton, Icon, IconButton } from '@fluentui/react';

import CodeBlock from './CodeBlock';

import { 
    buttonStyles,
    cardStyles, 
    cardHoverStyles,
    imageContainerStyles,
    contentStyles,
    iconButtonStyles
} from './styles';

interface ServiceItem {
    thumbnail: string;
    uri: string;
    title: string;
    short_description: string;
    description: string;
    install: string;
    code: string;
    documentation?: Map<string, string>;
}

interface ServiceCardProps {
    service: ServiceItem;
    isOpen: boolean;
    onShowModal: () => void;
    onHideModal: () => void;
}

const ServiceCard: React.FC<ServiceCardProps> = ({ service, isOpen, onShowModal, onHideModal }) => {
    const imageString = "./images/" + service.thumbnail;

    const links: React.ReactNode[] = [];

    if ( service.documentation )  {
        Object.entries(service.documentation).forEach(([name, url]) => {
            links.push(
                <li key={name} style={{ marginBottom: '0.5rem' }}>
                    <Card.Link 
                        style={{ textAlign: "left", paddingTop: "1rem", fontSize: 14 }} 
                        href={url} 
                        target="_blank" 
                        rel="noopener noreferrer"
                    >
                        {name}
                    </Card.Link>
                </li>
            );
        });
    }


    return (
        <Card style={cardStyles} onMouseEnter={(e) => {
            Object.assign(e.currentTarget.style, cardHoverStyles);
        }}
        onMouseLeave={(e) => {
            Object.assign(e.currentTarget.style, cardStyles);
        }}
        >
            <div
                style={imageContainerStyles}
                onMouseEnter={(e) => {
                    const button = e.currentTarget.querySelector('.hover-button') as HTMLButtonElement;
                    const image = e.currentTarget.querySelector('.dashboard-img') as HTMLImageElement;
                    if (button && image) {
                        button.style.display = 'block';
                        image.style.opacity = "0.5";
                    }
                }}
                onMouseLeave={(e) => {
                    const button = e.currentTarget.querySelector('.hover-button') as HTMLButtonElement;
                    const image = e.currentTarget.querySelector('.dashboard-img') as HTMLImageElement;
                    if (button && image) {
                        button.style.display = 'none';
                        image.style.opacity = "1";
                    }
                }}
            >
                <Card.Img
                    className="dashboard-img"
                    variant="top"
                    src={imageString}
                    style={{
                        marginTop: 'auto',
                        margin: 'auto',
                    }}
                />
                <DefaultButton
                    className="hover-button"
                    style={buttonStyles}
                    onClick={onShowModal}
                >
                    Read More
                    <Icon iconName='ArrowUpRight8' style={{ paddingLeft: "0.5rem" }}></Icon>
                </DefaultButton>
                
                <Modal
                    isOpen={isOpen}
                    onDismiss={onHideModal}
                    isBlocking={false}
                    containerClassName={contentStyles.container}
                  >
                    <div className={contentStyles.header}>
                      <h2>
                      {service.title}
                      </h2>
                      <IconButton
                        styles={iconButtonStyles}
                        ariaLabel="Close popup modal"
                        onClick={onHideModal}
                        iconProps={{ iconName: 'Cancel' }}
                      />
                    </div>

                    <div style={{ marginLeft: "1.5rem", marginRight: "1.5rem", marginBottom: "2rem" }}>
                    <Card.Text style={{
                        textAlign: "left",
                        fontSize: 14
                    }}>
                        {service.description}
                    </Card.Text>

                    <div>
                        <h4 style={{ fontSize: 14, fontWeight: 600 }}>
                            Service Location
                        </h4>
                        <Card.Link href={service.uri} style={{ textAlign: "left", paddingTop: "1rem", fontSize: 14 }}>
                            {service.uri}
                        </Card.Link>
                    </div>


                    <div style={{ marginTop: "1rem" }}>
                        <h4 style={{ fontSize: 14, fontWeight: 600 }}>
                            Install
                        </h4>
                        <CodeBlock code={service.install} language="Shell" />
                    </div>

                    <div style={{ marginTop: "1rem" }}>
                        <h4 style={{ fontSize: 14, fontWeight: 600 }}>Example Code</h4>
                        <CodeBlock code={service.code} language="Python" />
                    </div>

                    {
                        links.length > 0 && (
                        <div>
                            <h4 style={{ fontSize: 14, fontWeight: 600 }}>
                                Documentation
                            </h4>
                            <ul>
                            {links}
                            </ul>
                        </div>
                    )}
                    </div>

                </Modal>
            </div>
            
            <Card.Body style={{
                height: "250px",
                overflow: 'hidden',
                position: 'relative'
            }}>
                <Card.Title style={{
                    fontWeight: "400",
                    textAlign: "left",
                    paddingTop: "1rem",
                    paddingLeft: "1rem",
                    fontSize: 20,
                    color: "#083a59"
                }}>
                    {service.title}
                </Card.Title>
                <Card.Text style={{
                    textAlign: "left",
                    paddingTop: "1rem",
                    paddingLeft: "1rem",
                    paddingRight: "1rem",
                    fontSize: 14
                }}>
                    {service.short_description.length > 200
                        ? service.short_description.substring(0, 200) + "..."
                        : service.short_description
                    }
                </Card.Text>
            </Card.Body>
        </Card>
    );
};

export default ServiceCard;