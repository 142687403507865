import { DefaultButton } from '@fluentui/react';
import { useAuth } from 'react-oidc-context';

import { dropDownButton } from "./styles";

export const LogoutButton = () => {
  const auth = useAuth();

  const handleLogout = () => {
    auth.removeUser();
  };

  return (
    <DefaultButton
        text='Logout'
        className={dropDownButton}
        onClick={handleLogout}
    />
  );
};