import L from "leaflet";
import { useEffect } from "react";
import { useExploreSelector } from "pages/Explore/state/hooks";
import axios from 'axios';

import { useConfig } from 'ConfigProvider';

const cogLayerGroup = L.layerGroup();

const useItemLayer = (
  mapRef: React.MutableRefObject<L.Map | null>,
  mapReady: boolean
) => {
  const { detail } = useExploreSelector(s => s);
  const { layers, layerOrder, currentEditingLayerId } = useExploreSelector(
    s => s.collection
  );

  const appConfig = useConfig();

  // If we are showing the detail as a tile layer, craft the tileJSON request
  // with the selected item (TODO: make custom redux selector, it's used elsewhere)
  const stacItemForMosaic = detail.display.showSelectedItemAsLayer
    ? detail.selectedItem
    : null;
  const isItemLayerValid = Boolean(stacItemForMosaic);

  // Shuffle the map layer order when the layerOrder changes
  useEffect(() => {
    if (!mapRef.current || !mapReady) return;

    const map = mapRef.current;

  }, [mapReady, mapRef]);

  // Add a mosaic layer endpoint to the map
  useEffect(() => {
    if (!mapRef.current || !mapReady) return;

    const map = mapRef.current;

    let layercount = 0;
    cogLayerGroup.eachLayer(function(){ layercount += 1; });
    if ( layercount >= 1 ) {
      cogLayerGroup.clearLayers()
    }

    if (stacItemForMosaic?.assets) {

      if ('tilejson' in stacItemForMosaic?.assets) {
        const setIMG = stacItemForMosaic?.assets["tilejson"].href

        axios.get(setIMG)
        .then(response => {
          const r = response.data;

          if (response.status === 200 ) {
            const dataLayer = L.tileLayer(r.tiles[0]).setZIndex(200);
            dataLayer.addTo(cogLayerGroup)
            cogLayerGroup.addTo(map);
          }
        })
        .catch(error => {
          console.error('Error fetching TileJSON:', error);
        });
      }
      else {
        for (var asset in Object.values(stacItemForMosaic?.assets)) {
          var currAsset = Object.values(stacItemForMosaic?.assets)[asset]

          if (currAsset.type?.includes("geotiff") && currAsset.type?.includes("cloud-optimized") ) {
            const setIMG = currAsset.href

            if (setIMG) {

              // Make HTTP GET request to fetch TileJSON
              axios.get(`${appConfig.config.tiTiler}/cog/tilejson.json`, {
                params: {
                    url: currAsset.href,
                    bidx: "1"
                }
              })
              .then(response => {
                const r = response.data;

                if (response.status === 200 ) {

                  const dataLayer = L.tileLayer(r.tiles[0]).setZIndex(200);
                  dataLayer.addTo(cogLayerGroup)
                  cogLayerGroup.addTo(map);
                }
              })
              .catch(error => {
                console.error('Error fetching TileJSON:', error);
              });
              break
            }
          }
        }
      }
    }

  }, [
    appConfig,
    mapRef,
    mapReady,
    stacItemForMosaic,
    isItemLayerValid,
    layers,
    currentEditingLayerId,
  ]);
};

export default useItemLayer;
