import { useExploreSelector } from "pages/Explore/state/hooks";

import { itemOutlineLayer } from "../../../utils/layers"

import L from "leaflet";


const locallayerGroup = L.layerGroup();

export const useMapDrawTools = (
  mapRef: React.MutableRefObject<L.Map | null>,
  mapReady: boolean
) => {

  const { selectedItem } = useExploreSelector(s => s.detail);

  // Initialize the drawing manager when the map is ready
  if ( mapRef.current && mapReady ) {

    if (mapRef.current.hasLayer(locallayerGroup)) {
    } else {
      locallayerGroup.addTo(mapRef.current)
    }

    if ( selectedItem?.geometry ) {
      const bbox = selectedItem.geometry

      let layercount = 0;
      locallayerGroup.eachLayer(function(){ layercount += 1; });
      if ( layercount >= 1 ) {
        locallayerGroup.clearLayers()
      }

      L.geoJSON(
        bbox, {
          style: {
            color: "#40bee0",
            fillOpacity: 0,
            weight: 2,
          }
        }
      ).addTo(locallayerGroup);

    } else if ( !selectedItem ) {
      locallayerGroup.clearLayers()
    }
  }
};

