import { Text } from "@fluentui/react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router";

import Keywords from "./Keywords";
import { IPcCollection } from "types/stac";
import { useDataConfig } from "components/state/DataConfigProvider";

import banners from "config/banners.yml";

interface BannerProps {
  collection: IPcCollection;
  forceGradient?: boolean;
}

function getRandomArbitrary(min: number, max: number) {
  return Math.random() * (max - min) + min;
}

const Banner: React.FC<BannerProps> = ({ collection, forceGradient = false }) => {
  const { collectionConfig, groupConfig } = useDataConfig();
  const navigate = useNavigate();
  if (!collection) return null;

  const handleClick = (keyword: string) => {
    navigate({ pathname: "/catalog", search: `filter=${keyword}` });
  };

  let imgList;

  // Check if the category exists in collectionConfig[collection.id]
  const category = collectionConfig[collection.id]?.category || "Sentinel-2";
  
  try {
    // Attempt to assign the category's banners to imgList
    imgList = banners[category];
    
    // If the category doesn't exist in banners, imgList will be undefined
    if (!imgList) throw new Error("Category not found in banners");
  } catch (error) {
    // Fallback to "Sentinel-2" if category is not found in banners or other error occurs
    imgList = banners["Sentinel-2"];
  }
  let useIndex = Math.floor(getRandomArbitrary(0, imgList.length))
  const imgUrl = imgList[useIndex]


  const groupId = collection["msft:group_id"];
  const hasGroup = groupId && groupConfig[groupId];

  const bannerBreadcrumbs = hasGroup ? (
    <>
      <Link style={linkStyles} to="/catalog">
        Datasets
      </Link>{" "}
      {" > "}
      <Link style={linkStyles} to={`/dataset/group/${groupId}`}>
        {groupConfig[groupId].title}
      </Link>
    </>
  ) : (
    <Link to="/catalog">Datasets</Link>
  );

  const gradientStyle = forceGradient ? linearGradient : undefined;

  return (
    <div
      className="page-header"
      style={{
        backgroundImage: `url(${imgUrl})`,
      }}
    >
      <div className="page-header--gradient" style={gradientStyle}>
        <div className="grid-content">
          <Text
            block
            className="breadcrumbs"
            styles={{ root: { color: "#fff", fontWeight: 500, marginTop: 5 } }}
          >
            {bannerBreadcrumbs}
          </Text>
          <h1 style={{ color: "#fff" }}>{collection.title}</h1>
          <Keywords
            dark
            keywords={collection.keywords}
            maxDisplayed={8}
            onClick={handleClick}
          />
        </div>
      </div>
    </div>
  );
};

export default Banner;

const linearGradient: React.CSSProperties = {
  background: "linear-gradient(90deg, rgb(76 68 68 / 68%), 33%, transparent)",
};

const linkStyles: React.CSSProperties = {
  textDecoration: "none",
};
