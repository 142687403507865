import L from "leaflet";

export const itemLineLayerName = "stac-item-line";
export const itemLineLayer = L.geoJSON(undefined, {
  style: {
    color: "rgb(0, 120, 212)",
    weight: 2,
  },
  filter: function(feature) {
    // Filter out features that are not Polygon or MultiPolygon
    const geometryType = feature.geometry.type;
    return geometryType === "Polygon" || geometryType === "MultiPolygon";
  }
});

export const itemOutlineLayerName = "stac-item-outline";
export const itemOutlineLayer = L.geoJSON(undefined, {
  style: {
    color: "#fff",
    weight: 4,
  },
  filter: function(feature) {
    // Filter out features that are not Polygon or MultiPolygon
    const geometryType = feature.geometry.type;
    return geometryType === "Polygon" || geometryType === "MultiPolygon";
  }
});

