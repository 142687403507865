import { useState } from "react";
import { ActionButton, getTheme } from "@fluentui/react";
import { useTimeoutFn } from "react-use";

const Footer = ({ onGrid = true }) => {
  const theme = getTheme();
  // Allow users to manage their cookie consent preferences. Not all regions
  // require consent, and the cookie library will do a no-op if the consent dialog is
  // launched in a non-required region. So check for requirements before rendering the button.
  // Defaulting to true is primarily to satisfy the compliance test, to ensure
  // that manage button is rendered on first render, while the consent library
  // may still be loading.
  const [isConsentRequired, setIsConsentRequired] = useState(true);

  useTimeoutFn(() => {
    // Cookie consent is determined regionally. If it can't be determined, default
    // to requiring consent.
    const consent = window?.siteConsent?.isConsentRequired;
    setIsConsentRequired(consent === undefined ? true : consent);
  }, 100);

  const navClass = onGrid ? "grid-content" : "off-grid-content";

  return (
    <footer
      className="context-uhf"
      style={{
        borderTop: "1px solid",
        borderColor: theme.palette.neutralQuaternaryAlt,
      }}
    >
      <div className={`c-uhff-base ${navClass}`} style={{ paddingBottom: 2 }}>
        <nav aria-label="EODC links">
          <ul
            className="c-list f-bare"
            style={{ margin: 0 }}
          >
            <li className="x-hidden-focus">
              <a
                className="c-uhff-link"
                href="https://eodc.eu/"
                data-mscc-ic="false">
              @{new Date().getFullYear()} Earth Observation Data Centre GmbH
              </a>
            </li>
            <li id="c-uhff-footer_sitemap">
              <a
                className="c-uhff-link"
                href="https://eodc.eu/career/"
                data-mscc-ic="false"
              >
                Career
              </a>
            </li>
            <li id="c-uhff-footer_sitemap">
              <a
                className="c-uhff-link"
                href="https://eodc.eu/contact/"
                data-mscc-ic="false"
              >
                Contact
              </a>
            </li>
            <li id="c-uhff-footer_sitemap">
              <a
                className="c-uhff-link"
                href="https://eodc.eu/imprint/"
                data-mscc-ic="false"
              >
                Imprint
              </a>
            </li>
            <li id="c-uhff-footer_sitemap">
              <a
                className="c-uhff-link"
                href="https://eodc.eu/disclaimer/"
                data-mscc-ic="false"
              >
                Disclaimer
              </a>
            </li>
            <li id="c-uhff-footer_sitemap">
              <a
                className="c-uhff-link"
                href="https://eodc.eu/dataprotection/"
                data-mscc-ic="false"
                data-m='{"cN":"Footer_Sitemap_nav","id":"n1c7c1c1m1r1a2","sN":1,"aN":"c7c1c1m1r1a2"}'
              >
                Data protection
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </footer>
  );
};

export default Footer;